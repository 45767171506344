<template>
  <main data-aos="fade-up" data-aos-duration="1000">
    <!-- hero-area start -->
    <section class="breadcrumb-bg pt-100 pb-100">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 col-md-9">
            <div class="page-title">
              <h1>News</h1>
            </div>
          </div>
          <div class="col-lg-3 col-md-3 d-flex justify-content-start justify-content-md-end align-items-center">
            <div class="page-breadcumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb ">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">News</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- hero-area end -->

    <!-- blog-area start -->
    <section class="blog-area pt-120 pb-80" ref="newsSection">
      <div class="container">
        <div class="row mobile-version">
          <div class="col-lg-4">
            <newsLeftBar />
          </div>
          <div class="col-lg-8">
            <div v-if="next_btn" class="not-found">not found</div>
            <div class="loading" v-if="loading">
              <img src="../assets/loading-animacion.gif" alt="">
            </div>
            <article class="postbox post format-image mb-40" v-for="news in news_data" :key="news.id" v-if="news.status === 'published' && !loading">
              <div class="postbox__thumb">
                <router-link :to="{ name: 'article', params: params({url: news.slug, title: news.title}) }" class="default-img">
                  <img :src="news.image" alt="blog image">
                </router-link>
              </div>
              <div class="postbox__text p-50">
                <div class="post-meta mb-15">
                  <span><i class="far fa-calendar-check"></i> {{moment(news.date)}} </span>
                </div>
                <h3 class="blog-title">
                  <router-link :to="{ name: 'article', params: params({url: news.slug, title: news.title}) }">{{news.title}}</router-link>
                </h3>
                <div class="post-text mb-20">
                  <p>{{news.description}}</p>
                </div>
                <div class="read-more mt-30">
                  <router-link :to="{ name: 'article', params: params({url: news.slug, title: news.title}) }" class="btn theme-btn">
                    read more
                  </router-link>
                </div>
              </div>
            </article>
            <div class="basic-pagination basic-pagination-2 mb-40">
              <ul>
                <li>
                  <button @click.prevent="goPrevPage()" :disabled="news_data && +offset === 0">
                    <i class="fas fa-angle-double-left"></i>Previous
                  </button>
                </li>
                <li>
                  <button @click.prevent="goNextPage()" :disabled="news_data && +news_data.length < 3">
                    Next Page <i class="fas fa-angle-double-right"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- blog-area end -->
  </main>
</template>

<script>
    import api from "../services/api";
    import newsLeftBar from "../components/news-left-bar";
    import moment from "moment";
    export default {
        name: "news",
        data() {
            return {
                news_data: [],
                loading: true,
                showPopup: false,
                pagination: null,
                offset: 0,
                limit: 3,
                next_btn: false,
            }
        },
        async mounted() {
            window.scrollTo(0,0);
            this.loading = true;
        },
        methods: {
            async getBlog(offset, limit) {
                this.news_data = [];
                this.loading = true;
                this.news_data = await api.news(offset, limit);
                for (const value of this.news_data) {
                    if (value.image !== null && typeof value.image === "number") {
                        let img = await api.getFiles(value.image);
                        if (img !== null) {
                            value.image = img.data['data']['full_url'];
                        }
                    }
                }
                this.news_data.href = window.location.origin + '/' + 'article/' +this.news_data.slug;
                if (this.news_data.length < 3) this.next_btn = false;
                this.loading = false;
            },
            init() {  
                if (this.$route.query.offset >= 0) {
                    this.offset = this.$route.query.offset;
                    this.getBlog(this.$route.query.offset, this.limit);
                } else {
                    this.$router.push({path: '/'});
                }
            },
            goPrevPage() {
                this.next_btn = false;
                if(this.offset !==0 ) {
                    this.offset = +this.offset - +this.limit;
                    this.$router.push({
                        name: 'articles',
                        query: { offset: this.offset}
                    })
                }
                this.$refs.newsSection.scrollIntoView();
            },
            goNextPage() {
                this.offset = +this.offset + +this.limit;
                this.$router.push({
                    name: 'articles',
                    query: { offset: this.offset}
                });
                this.$refs.newsSection.scrollIntoView();
            },
            moment (date) {
                return moment(date).format('MMMM D, YYYY');
            },
            params(newParams) {
                return {
                    ...this.$route.params,
                    ...newParams
                }
            },
            query(newQuery) {
                return {
                    ...this.$route.query,
                    ...newQuery
                }
            },
        },
        components: {
            newsLeftBar
        },
        watch: {
            '$route.query': {
                immediate: true,
                handler() {
                    this.init();
                }
            }
        },
    }
</script>

<style scoped>
  .breadcrumb-bg {
    background-image: url("../assets/img/blog/details/news-banner.png");
    position: relative;
  }
  .breadcrumb-bg::after {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.72);
  }
  .breadcrumb-bg .container {
    position: relative;
    z-index: 2;
  }
  .default-img {
    height: 440px;
    display: block;
  }
  .default-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .loading {
    width: 100%;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .toggle-button {
    background: #1188FF;
    color: #FFF;
    border: 0;
    border-radius: 2px;
    padding: 10px;
    font-size: 14px;
    cursor: pointer;
    outline: none;
    transition: 0.2s all;
  }

  .toggle-button:hover {
    background: #1080F8;
  }

  .popup-box {
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translateX(-50%);
    background: #F0F8FF;
    border-radius: 1px;
    box-shadow: 1px 1px 15px 0 rgba(0,0,0,0.2);
    padding: 40px;
    color: #555585;
  }

</style>
